import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { KeyFilterPattern } from 'primeng/keyfilter';

export type FormInputType =
  | 'textWithSpaces'
  | 'textNoSpaces'
  | 'alphanumericWithSpace'
  | 'alphanumericNoSpace'
  | 'alphanumericWithDashNoSpace'
  | 'integer'
  | 'number'
  | 'email'
  | 'url';
@Component({
  selector: 'app-form-input',
  templateUrl: './form-input.component.html',
  styleUrl: './form-input.component.scss',
})
export class FormInputComponent {
  @Input() placeholder = '';
  @Input() errorLabel = '';
  @Input() control: FormControl = new FormControl();
  @Input() tooltip?: string;
  @Input() type?: FormInputType;
  @Input() readOnly: boolean = false;
  @Input() disabled: boolean = false;

  @Output() valueChange = new EventEmitter<string>();

  alphaWithSpace: RegExp = /^[a-zA-Z_ ]*$/;
  alphanumericWithSpace: RegExp = /^[a-zA-Z0-9_ ]*$/;
  alphanumericWithDashNoSpace: RegExp = /^[a-zA-Z0-9-]*$/;
  urlPattern: RegExp = /^[a-zæøåA-ZÆØÅ0-9\s\-._~:/?#[\]@!$&'()*+,;=]*$/;


  getInputType(): KeyFilterPattern | RegExp | null {
    switch (this.type) {
      case 'alphanumericWithSpace':
        return this.alphanumericWithSpace;
      case 'alphanumericWithDashNoSpace':
        return this.alphanumericWithDashNoSpace;
      case 'alphanumericNoSpace':
        return 'alphanum';
      case 'textNoSpaces':
        return 'alpha';
      case 'textWithSpaces':
        return this.alphaWithSpace;
      case 'number':
        return 'num';
      case 'integer':
        return 'int';
      case 'email':
        return 'email';
      case 'url':
        return this.urlPattern;
      default:
        return null;
    }
  }

  valueChanged(event: Event) {
    const target = event.target as HTMLInputElement;
    this.valueChange.emit(target.value);
  }
}
