import { Component, Input, OnInit } from '@angular/core';
import { Detection } from '@shared/types';

@Component({
  selector: 'app-table-camera-detection',
  templateUrl: './table-camera-detection.component.html',
  styleUrl: './table-camera-detection.component.scss',
})
export class TableCameraDetectionComponent implements OnInit {
  @Input() input?: { value: string; detection: Detection; callback?: () => void };

  hasImages = false;
  color = "gray";

  ngOnInit() {
    this.hasImages = (this.input?.detection?.imageUrls?.length ?? 0) > 0;

    // Add color schematics to buttons by bucketcomplete
    if(this.input?.detection) {
      if(this.input?.detection.bucketComplete  !== null) {
        if(this.input?.detection.bucketComplete == false) { this.color = "red" }
        if(this.input?.detection.bucketComplete == true) { this.color = "green" }
      }
    }
  }

  showImages() {
    if (this.input?.callback && this.input.detection.imageUrls && this.input.detection.imageUrls.length) {
      this.input.callback();
    }
  }
}
